import { ParseQuery } from './parse-query';

import './../extensions/reg-exp+escape';

import { User, Coupon, CouponGroup, CouponStatus } from '@shared/data';

import { CouponStatusQuery } from './coupon-status-query';

export class CouponQuery extends ParseQuery<Coupon> {
  constructor() {
    super(Coupon);
  }

  public group(group: CouponGroup) {
    this.equalTo(Coupon.GROUP_KEY, group);
    return this;
  }

  public search(searchText: string) {
    let queries: Parse.Query[] = [];

    queries.push(new CouponQuery().searchKey(Coupon.CODE_KEY, searchText));
    queries.push(new CouponQuery().searchKey(Coupon.NAME_KEY, searchText));
    queries.push(new CouponQuery().searchKey(Coupon.ORDER_NUMBER_KEY, searchText));

    this.orQuery(queries);

    return this;
  }

  public distributor(distributor: User) {
    this.equalTo(Coupon.DISTRIBUTOR_KEY, distributor);
    return this;
  }

  public teamLeader(teamLeader: User) {
    this.equalTo(Coupon.TEAM_LEADER_KEY, teamLeader);
    return this;
  }

  public statusCode(code: string) {
    let query = new CouponStatusQuery().equalTo(CouponStatus.CODE_KEY, code);
    
    this.matchesQuery(Coupon.STATUS_KEY, query);
    return this;
  }

  public active() {
    let now = new Date();

    this.lessThanOrEqualTo(Coupon.START_DATE_KEY, now).greaterThan(Coupon.END_DATE_KEY, now);
    return this;
  }

  public inactive() {
    let now = new Date();

    this.greaterThanOrEqualTo(Coupon.START_DATE_KEY, now);
    return this;
  }

  public expired() {
    let now = new Date();
    
    this.lessThan(Coupon.END_DATE_KEY, now);
    return this;
  }
}
