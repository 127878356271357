import { ParseObject } from './parse-object';
import { FileRef } from './file-ref';
import { Banner } from './banner';

export interface MarketingPageSectionNav {
  title: string;
  link: string;
  sectionId?: string;
}

export class MarketingPageSection extends ParseObject {
  public static readonly CLASS_NAME = 'MarketingPageSection';
  public static readonly TITLE_KEY = 'title';
  public static readonly SUBTITLE_KEY = 'subtitle';
  public static readonly DESC_KEY = 'desc';
  public static readonly TEMPLATE_KEY = 'template';
  public static readonly IMAGE_KEY = 'image';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly BACKGROUND_COLOR_KEY = 'backgroundColor';
  public static readonly BACKGROUND_IMAGE_KEY = 'backgroundImage';
  public static readonly BACKGROUND_MOBILE_IMAGE_KEY = 'backgroundMobileImage';
  public static readonly GOOGLE_PLAY_LINK_KEY = 'googlePlayLink';
  public static readonly APPLE_STORE_LINK_KEY = 'appleStoreLink';
  public static readonly DOWNLOAD_NOW_KEY = 'downloadNow';
  public static readonly BACKGROUND_TEXT_KEY = 'backgroundText';
  public static readonly NAVIGATIONS_KEY = 'navigations';
  public static readonly BANNER_KEY = 'banner';

  constructor() {
    super(MarketingPageSection.CLASS_NAME);
  }

  public static create(template: string) {
    const section = new MarketingPageSection()

    section.template = template
    section.navigations = []

    return section;
  }

  public get template(): string {
    return this.get(MarketingPageSection.TEMPLATE_KEY);
  }

  public set template(template: string) {
    this.set(MarketingPageSection.TEMPLATE_KEY, template);
  }

  public get title(): string {
    return this.get(MarketingPageSection.TITLE_KEY);
  }

  public set title(title: string) {
    this.set(MarketingPageSection.TITLE_KEY, title);
  }

  public get subtitle(): string {
    return this.get(MarketingPageSection.SUBTITLE_KEY);
  }

  public set subtitle(subtitle: string) {
    this.set(MarketingPageSection.SUBTITLE_KEY, subtitle);
  }

  public get downloadNow(): string {
    return this.get(MarketingPageSection.DOWNLOAD_NOW_KEY);
  }

  public set downloadNow(downloadNow: string) {
    this.set(MarketingPageSection.DOWNLOAD_NOW_KEY, downloadNow);
  }

  public get description(): string {
    return this.get(MarketingPageSection.DESC_KEY);
  }

  public set description(desc: string) {
    this.set(MarketingPageSection.DESC_KEY, desc);
  }

  public get image(): FileRef {
    return this.get(MarketingPageSection.IMAGE_KEY);
  }

  public set image(image: FileRef) {
    this.set(MarketingPageSection.IMAGE_KEY, image);
  }

  public get sortOrder(): number {
    return this.get(MarketingPageSection.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(MarketingPageSection.SORT_ORDER_KEY, sortOrder);
  }

  public get backgroundColor(): string {
    return this.get(MarketingPageSection.BACKGROUND_COLOR_KEY);
  }

  public set backgroundColor(backgroundColor: string) {
    this.set(MarketingPageSection.BACKGROUND_COLOR_KEY, backgroundColor);
  }

  public get backgroundImage(): FileRef {
    return this.get(MarketingPageSection.BACKGROUND_IMAGE_KEY);
  }

  public set backgroundImage(backgroundImage: FileRef) {
    this.set(MarketingPageSection.BACKGROUND_IMAGE_KEY, backgroundImage);
  }

  public get backgroundMobileImage(): FileRef {
    return this.get(MarketingPageSection.BACKGROUND_MOBILE_IMAGE_KEY);
  }

  public set backgroundMobileImage(backgroundMobileImage: FileRef) {
    this.set(MarketingPageSection.BACKGROUND_MOBILE_IMAGE_KEY, backgroundMobileImage);
  }

  public get googlePlayLink(): string {
    return this.get(MarketingPageSection.GOOGLE_PLAY_LINK_KEY);
  }

  public set googlePlayLink(googlePlayLink: string) {
    this.set(MarketingPageSection.GOOGLE_PLAY_LINK_KEY, googlePlayLink);
  }

  public get appleStoreLink(): string {
    return this.get(MarketingPageSection.APPLE_STORE_LINK_KEY);
  }

  public set appleStoreLink(appleStoreLink: string) {
    this.set(MarketingPageSection.APPLE_STORE_LINK_KEY, appleStoreLink);
  }

  public get backgroundText(): string {
    return this.get(MarketingPageSection.BACKGROUND_TEXT_KEY);
  }

  public set backgroundText(backgroundText: string) {
    this.set(MarketingPageSection.BACKGROUND_TEXT_KEY, backgroundText);
  }

  public get navigations(): MarketingPageSectionNav[]  {
    return this.get(MarketingPageSection.NAVIGATIONS_KEY);
  }

  public set navigations(navigations: MarketingPageSectionNav[]) {
    this.set(MarketingPageSection.NAVIGATIONS_KEY, navigations);
  }

  public get banner(): Banner  {
    return this.get(MarketingPageSection.BANNER_KEY);
  }

  public set banner(banner: Banner) {
    this.set(MarketingPageSection.BANNER_KEY, banner);
  }
}

Parse.Object.registerSubclass(MarketingPageSection.CLASS_NAME, MarketingPageSection);
