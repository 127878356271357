import * as _ from 'lodash';

import { Product } from './product';
import { ProductVariant } from './product-variant';
import { FileRef } from './file-ref';

export class ProductImage {
  public key: string;
  public value: string;
  public images: FileRef[];

  public static images(key: string, variants: ProductVariant[]) {
    if (!variants) {
      return [];
    }

    let map = {};

    variants.forEach(v => {
      let value = v.properties[key];

      if (value) {
        let images = map[value];
        map[value] = _.compact(_.concat(v.images, images));
      }
    });

    let images: ProductImage[] = _.keys(map).map(k => {
      return {
        key: key,
        value: k,
        images: map[k]
      };
    });

    return images;
  }
}