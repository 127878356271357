import { PaymentMethod } from './payment-method';
import { DeliveryItem } from './delivery-item';
import { ParseObject } from './parse-object';

import { IAddress } from './address';
import { User } from './user';
import { DeliveryStatus } from './delivery-status';

export class Delivery extends ParseObject {
  public static readonly CLASS_NAME = 'Delivery';
  public static readonly STATUS_KEY = 'status';
  public static readonly WEIGHT_KEY = 'weight';
  public static readonly ESTIMATE_DELIVERY_DATE_KEY = 'estimateDeliveryDate';
  public static readonly SHIPPING_DATE_KEY = 'shippingDate';
  public static readonly DELIVERY_NUMBER_KEY = 'deliveryNumber';
  public static readonly ORDER_NUMBER_KEY = 'orderNumber';
  public static readonly ORDER_ID_KEY = 'orderID';
  public static readonly DELIVERY_USER_KEY = 'deliveryUser';
  public static readonly SHIP_TO_KEY = 'shipTo';
  public static readonly DELIVERY_ITEMS_KEY = 'deliveryItems';
  public static readonly CUSTOMER_KEY = 'customer';
  public static readonly LOGS_KEY = 'logs';
  public static readonly AMOUNT_KEY = 'amount';
  public static readonly PAYMENT_METHOD_KEY = 'paymentMethod';

  constructor() {
    super(Delivery.CLASS_NAME);
  }

  public set status(status: DeliveryStatus) {
    this.set(Delivery.STATUS_KEY, status);
  }

  public get status(): DeliveryStatus {
    return this.get(Delivery.STATUS_KEY);
  }

  public set weight(weight: number) {
    this.set(Delivery.WEIGHT_KEY, weight);
  }

  public get weight(): number {
    return this.get(Delivery.WEIGHT_KEY);
  }

  public set estimateDeliveryDate(estimateDeliveryDate: Date) {
    this.set(Delivery.ESTIMATE_DELIVERY_DATE_KEY, estimateDeliveryDate);
  }

  public get estimateDeliveryDate(): Date {
    return this.get(Delivery.ESTIMATE_DELIVERY_DATE_KEY);
  }

  public set deliveryNumber(deliveryNumber: number) {
    this.set(Delivery.DELIVERY_NUMBER_KEY, deliveryNumber);
  }

  public get deliveryNumber(): number {
    return this.get(Delivery.DELIVERY_NUMBER_KEY);
  }

  public get orderNumber(): string {
    return this.get(Delivery.ORDER_NUMBER_KEY);
  }

  public set orderNumber(orderNumber: string) {
    this.set(Delivery.ORDER_NUMBER_KEY, orderNumber);
  }

  public get orderId(): string {
    return this.get(Delivery.ORDER_ID_KEY);
  }

  public set orderId(orderId: string) {
    this.set(Delivery.ORDER_ID_KEY, orderId);
  }

  public set deliveryUser(deliveryUser: User) {
    this.set(Delivery.DELIVERY_USER_KEY, deliveryUser);
  }

  public get deliveryUser(): User {
    return this.get(Delivery.DELIVERY_USER_KEY);
  }

  public get deliveryItems(): DeliveryItem[] {
    return this.get(Delivery.DELIVERY_ITEMS_KEY);
  }

  public set deliveryItems(deliveryItems: DeliveryItem[]) {
    this.set(Delivery.DELIVERY_ITEMS_KEY, deliveryItems);
  }

  public get shipTo(): IAddress  {
    return this.get(Delivery.SHIP_TO_KEY);
  }

  public set shipTo(address: IAddress) {
    this.set(Delivery.SHIP_TO_KEY, address);
  }

  public get customer(): User  {
    return this.get(Delivery.CUSTOMER_KEY);
  }

  public set customer(customer: User) {
    this.set(Delivery.CUSTOMER_KEY, customer);
  }

  public get logs(): {[index:string]: Date}  {
    return this.get(Delivery.LOGS_KEY);
  }

  public set logs(logs: {[index:string]: Date}) {
    this.set(Delivery.LOGS_KEY, logs);
  }

  public get amount(): number  {
    return this.get(Delivery.AMOUNT_KEY);
  }

  public set amount(amount: number) {
    this.set(Delivery.AMOUNT_KEY, amount);
  }

  public get paymentMethod(): PaymentMethod  {
    return this.get(Delivery.PAYMENT_METHOD_KEY);
  }

  public set paymentMethod(paymentMethod: PaymentMethod) {
    this.set(Delivery.PAYMENT_METHOD_KEY, paymentMethod);
  }

  public get canAssignedDelivery(): boolean {
    if (!this.status || (this.status.code == DeliveryStatus.READY_TO_PICKUP_STATUS)) {
      return true;
    }
    return false;
  }
}

Parse.Object.registerSubclass(Delivery.CLASS_NAME, Delivery);
