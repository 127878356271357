import { ParseObject } from './parse-object';

export class CouponStatus extends ParseObject {
  public static readonly CLASS_NAME = 'CouponStatus';
  public static readonly NAME_KEY = 'name';
  public static readonly CODE_KEY = 'code';
  public static readonly COLOR_KEY = 'color';

  public static readonly USED_STATUS = 'used';
  public static readonly UNUSED_STATUS = 'unused';

  constructor() {
    super(CouponStatus.CLASS_NAME);
  }

  public get name(): string {
    return this.get(CouponStatus.NAME_KEY);
  }

  public set name(name: string) {
    this.set(CouponStatus.NAME_KEY, name);
  }

  public get code(): string {
    return this.get(CouponStatus.CODE_KEY);
  }

  public set code(code: string) {
    this.set(CouponStatus.CODE_KEY, code);
  }

  public get color(): string {
    return this.get(CouponStatus.COLOR_KEY);
  }

  public set color(color: string) {
    this.set(CouponStatus.COLOR_KEY, color);
  }
}

Parse.Object.registerSubclass(CouponStatus.CLASS_NAME, CouponStatus);
