import { FileRef } from './file-ref';

import * as _ from 'lodash';

export class FakeUploadProgress {
  public progress: number;

  private internal: number;
  private files: FileRef[];

  constructor(file: FileRef | FileRef[], startImmediately: boolean = true) {
    if (file instanceof Array) {
      this.files = file;
    } else {
      this.files = [file];
    }

    if (startImmediately) {
      this.start();
    }
  }

  public start() {
    let size = _.sumBy(this.files, f => f.size);
    let duration = size / 100;
    let percent = duration / 100;

    if (percent < 100) {
      percent = 100;
    }

    this.progress = 1;
    this.internal = window.setInterval(() => {
      let completed = this.files.every(f => f.id != undefined);

      if (this.progress < 99) this.progress += 1;

      if (completed) {
        this.progress = 99;

        window.clearInterval(this.internal);
        window.setTimeout(() => {
          this.progress = 100;
        }, 1000);
      }
    }, percent);
  }
}
