import './../extensions/reg-exp+escape';

import { Pagination, Sort, ParseObject } from './../data';

export class ParseQuery<T extends Parse.Object> extends Parse.Query<T> {
  public static MAX_LIMIT = 100000

  public orQuery(queries: Parse.Query[]) {
    this['_orQuery'] (queries);
    return this;
  }

  public searchKey(key: string, searchText: string) {
    return this.matches(key, RegExp.escape(searchText), 'i');
  }

  public pagination(pagination: Pagination) {
    this.limit(pagination.pageSize).skip(pagination.pageSize * (pagination.page - 1));
    return this;
  }

  public sort(sort: Sort) {
    if (sort && sort.sortKey) {
      sort.ascending ? this.addAscending(sort.sortKey) : this.addDescending(sort.sortKey);
    }
    return this;
  }

  public andQuery(query: Parse.Query) {
    this.matchesKeyInQuery(ParseObject.OBJECT_ID_KEY, ParseObject.OBJECT_ID_KEY, query);
    return this;
  }
}