export class MarketingPageSectionTemplate {
  public static readonly IMAGE_LEFT = 'imageLeft';
  public static readonly IMAGE_RIGHT = 'imageRight';
  public static readonly INTRODUCTION = 'introduction';
  public static readonly DOWNLOAD_APP = 'downloadApp';
  public static readonly NAVIGATION = 'navigation';
  public static readonly BANNER = 'banner';

  public name: string;
  public label: string;
  public thumbnailUrl: string;
  public iconUrl?: string;

  public static templates() {
    return templates
  }
}

const templates: MarketingPageSectionTemplate[] = [
  {
    name: MarketingPageSectionTemplate.IMAGE_LEFT,
    label: 'Image Left',
    thumbnailUrl: './assets/img/marketing-page/image-left.png'
  },
  {
    name: MarketingPageSectionTemplate.IMAGE_RIGHT,
    label: 'Image Right',
    thumbnailUrl: './assets/img/marketing-page/image-right.png'
  },
  {
    name: MarketingPageSectionTemplate.INTRODUCTION,
    label: 'Introduction',
    thumbnailUrl: './assets/img/marketing-page/introduction.png'
  },
  {
    name: MarketingPageSectionTemplate.DOWNLOAD_APP,
    label: 'Download App',
    thumbnailUrl: './assets/img/marketing-page/download-app.png'
  },
  {
    name: MarketingPageSectionTemplate.NAVIGATION,
    label: 'Navigation',
    thumbnailUrl: './assets/img/marketing-page/navigation.png',
    iconUrl: './assets/img/marketing-page/navigation_icon.png'
  },
  {
    name: MarketingPageSectionTemplate.BANNER,
    label: 'Banner',
    thumbnailUrl: './assets/img/marketing-page/banner.png'
  }
];
