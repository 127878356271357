import * as _ from 'lodash'

import { Injectable } from '@angular/core';

@Injectable()
export class HelperService {
  constructor() {

  }

  public formatTag(tag: string, length: number): string {
    return _.truncate(_.replace(_.replace((tag), /(\n|\r|\r\n|\\\n)/gm, ''), /•*\s+•*/gm, ' '), { length: length, 'separator': '' })
  }
}
