import { ParseQuery } from './parse-query';

import { MarketingPage } from '@shared/data';

export class MarketingPageQuery extends ParseQuery<MarketingPage> {
  constructor() {
    super(MarketingPage);
  }

  public permalink(permalink: string) {
    this.equalTo(MarketingPage.PERMALINK_KEY, permalink)
    return this
  }
}