export class CouponType {
  public static readonly PERCENTAGE = 'percentage';
  public static readonly FIXED = 'fixed';

  public label: string;
  public key: string;
  public symbol: string;

  constructor() {

  }
}

export const COUPON_TYPES: CouponType[] = [
  {
    label: 'Percentage',
    key: 'percentage',
    symbol: '%'
  },
  {
    label: 'Fixed',
    key: 'fixed',
    symbol: '$'
  }
]