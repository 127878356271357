import { Category } from './../data/category';
import { ParseQuery } from './parse-query';

import { ProductVariantQuery } from './product-variant-query';
import { Product, ProductVariant, Attachment, Collection, Filter } from './../data';

import './../extensions/reg-exp+escape';

export class ProductQuery extends ParseQuery<Product> {
  constructor() {
    super(Product);
  }

  public enabled(enabled: boolean = true) {
    this.equalTo(Product.ENABLED_KEY, enabled);
    return this;
  }

  public includeDefaultVariant() {
    this.include([Product.DEFAULT_VARIANT_KEY, [Product.DEFAULT_VARIANT_KEY, ProductVariant.IMAGES_KEY].join('.')]);
    return this;
  }

  public includeVariants() {
    this.include([Product.VARIANTS_KEY, [Product.VARIANTS_KEY, ProductVariant.IMAGES_KEY].join('.')]);
    return this;
  }

  public includeAttachment(key: string) {
    this.include([key, [key, Attachment.IMAGE_KEY].join('.'), [key, Attachment.FILE_KEY].join('.')]);
    return this;
  }

  public includeAttachments() {
    this.includeAttachment(Product.CERTIFICATES_KEY);
    this.includeAttachment(Product.TECHNICAL_BOOKS_KEY);
    this.includeAttachment(Product.VIDEOS_KEY);
    this.includeAttachment(Product.INSTALLATIONS_KEY);
    this.includeAttachment(Product.IES_KEY);
    this.includeAttachment(Product.THREE_DIMENSION_DRAWING_KEY);
    return this;
  }

  public search(searchText: string) {
    this.matches(Product.NAME_KEY, RegExp.escape(searchText), 'i');
    return this;
  }

  public priceRange(low: number, high: number) {
    let priceQuery = new ProductVariantQuery().priceRange(low, high).enabled();
    let variantsQuery = new ProductQuery().matchesQuery(Product.VARIANTS_KEY, priceQuery);
    let defaultVariantQuery = new ProductQuery().matchesQuery(Product.DEFAULT_VARIANT_KEY, priceQuery);

    return this.orQuery([variantsQuery, defaultVariantQuery]);
  }

  public filters(filters: Filter[]) {
    let low: number;
    let high: number;
    let categories: Category[] = [];
    let collection: Collection;

    filters.forEach(filter => {
      switch (filter.type) {
        case Filter.COLLECTION_TYPE:
          if (filter.value) {
            collection = <Collection>filter.value;
          }
          break;
        case Filter.VIEWS_TYPE:
          if (filter.value) {
            this.addAscending(Product.VIEWS_KEY);
          }
          break;
        case Filter.CATEGORY_TYPE:
          if (filter.value) {
            categories = categories.concat(filter.value);
          }
          break;
        case Filter.PRICE_TYPE:
          if (filter.value) {
            low = filter.value[0];
            high = filter.value[1];
          }
          break;
          case Filter.BRAND_TYPE:
          if (filter.value) {
       
            this.equalTo(Product.BRAND_KEY, filter.value)
          }
          break;
      }
    });

    if (low || high) {
      let priceQuery: ProductQuery = new ProductQuery().priceRange(low, high);

      if (collection) {
        priceQuery.andQuery(collection.products.query());
      }

      this.andQuery(priceQuery);
    } else {
      if (collection) {
        this.andQuery(collection.products.query());
      }
    }

    if (categories.length) {
      this.containedIn(Product.CATEGORIES_KEY, categories);
    }

    return this;
  }

  public notRemoved() {
    this.notEqualTo(Product.REMOVED_KEY, true)
    return this;
  }

  public productType(key: string) {
    this.equalTo(Product.TYPE_KEY, key);
    return this;
  }
}
