import { ParseQuery } from './parse-query';
import { Address, User } from './../data';

export class AddressQuery extends ParseQuery<Address> {
  constructor() {
    super(Address);
  }

  public includeUser() {
    this.include(Address.USER_KEY);
    return this;
  }

  public user(user: User) {
    this.equalTo(Address.USER_KEY, user);
  }
}
