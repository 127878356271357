import { ParseObject } from './parse-object';

import * as _ from 'lodash';

import { FileRef } from './file-ref';
import { Language } from './language';
import { Size } from './size';
import { Event } from './event';

export class EventCategory extends ParseObject {
  public static readonly CLASS_NAME = 'EventCategory';
  public static readonly PARENT_KEY = 'parent';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly ENABLED_KEY = 'enabled';
  public static readonly NAME_KEY = 'name';
  public static readonly LONG_NAME_KEY = 'longName';
  public static readonly DESCRIPTION_KEY = 'desc';
  public static readonly IMAGE_KEY = 'image';

  constructor(id?: string) {
    super(EventCategory.CLASS_NAME);
    this.id = id;
  }

  public get parent(): EventCategory {
    return this.get(EventCategory.PARENT_KEY);
  }

  public set parent(parent: EventCategory) {
    this.set(EventCategory.PARENT_KEY, parent);
  }

  public get sortOrder(): number {
    return this.get(EventCategory.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(EventCategory.SORT_ORDER_KEY, sortOrder);
  }
  
  public get enabled(): boolean {
    return this.get(EventCategory.ENABLED_KEY);
  }

  public set enabled(enabled: boolean) {
    this.set(EventCategory.ENABLED_KEY, enabled);
  }

  public get name(): string {
    return this.get(EventCategory.NAME_KEY);
  }

  public set name(name: string) {
    this.set(EventCategory.NAME_KEY, name);
  }

  public get longName(): string {
    return this.get(EventCategory.LONG_NAME_KEY);
  }

  public set longName(longName: string) {
    this.set(EventCategory.LONG_NAME_KEY, longName);
  }

  public get description(): string {
    return this.get(EventCategory.DESCRIPTION_KEY);
  }

  public set description(description: string) {
    this.set(EventCategory.DESCRIPTION_KEY, description);
  }

  public get image(): FileRef {
    return this.get(EventCategory.IMAGE_KEY);
  }

  public set image(image: FileRef) {
    this.set(EventCategory.IMAGE_KEY, image);
  }

  public get thumbnailUrl() {
    return this.image ? this.image.localUrl : FileRef.NO_IMAGE_PATH;
  }

  public entries(): ParseObject[] {
    return _.compact([this.image, this]);
  }
}

Parse.Object.registerSubclass(EventCategory.CLASS_NAME, EventCategory);
