import { ProductVariant } from './product-variant';
import { ParseObject } from './parse-object';

import * as _ from 'lodash';

export class ProductOption {
  public static COLOR: string = 'Color';

  public name: string;
  public values: string[];

  constructor(name?: string, values?: string[]) {
    this.name = name;
    this.values = values;
  }

  public static options(variants: ProductVariant[]): ProductOption[] {
    let options: ProductOption[] = [];

    variants.forEach(v => {
      _.keys(v.properties).forEach(k => {
        let option = options.find(o => o.name == k);
        let value = v.properties[k];

        if (!option) {
          option = new ProductOption(k, []);
          options.push(option);
        }

        if (option.values.indexOf(value) == -1) {
          if (value) option.values.push(value);
        }
      });
    })

    return options;
  }
}
