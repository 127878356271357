import { ParseObject } from './parse-object';

export class Collection extends ParseObject {
  public static readonly CLASS_NAME = 'Collection';
  public static readonly ENABLED_KEY = 'enabled';
  public static readonly PRODUCTS_KEY = 'products';
  public static readonly NAME_KEY = 'name';
  public static readonly DESCRIPTION_KEY = 'desc';
  public static readonly CODE_KEY = 'code';

  public static readonly CODE_MOST_POPULAR = 'most_popular';
  public static readonly CODE_NEW_ARRIVALS = 'new_arrivals';

  constructor() {
    super(Collection.CLASS_NAME);
  }

  public static createCollection(id: string) {
    let object = new this();
    object.id = id;
    return object;
  }

  public get enabled(): boolean {
    return this.get(Collection.ENABLED_KEY);
  }

  public set enabled(enabled: boolean) {
    this.set(Collection.ENABLED_KEY, enabled);
  }

  public get products(): Parse.Relation {
    return this.relation(Collection.PRODUCTS_KEY);
  }

  public get name(): string {
    return this.get(Collection.NAME_KEY);
  }

  public set name(name: string) {
    this.set(Collection.NAME_KEY, name);
  }

  public get description(): string {
    return this.get(Collection.DESCRIPTION_KEY);
  }

  public set description(description: string) {
    this.set(Collection.DESCRIPTION_KEY, description);
  }

  public get code(): string {
    return this.get(Collection.CODE_KEY);
  }

  public set code(code: string) {
    this.set(Collection.CODE_KEY, code);
  }
}

Parse.Object.registerSubclass(Collection.CLASS_NAME, Collection);
