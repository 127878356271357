import { FileRef, Address } from '@shared/data';

export class User extends Parse.User {
  public static readonly CLASS_NAME: string = '_User';
  public static readonly FIRST_NAME_KEY: string = 'firstName';
  public static readonly LAST_NAME_KEY: string = 'lastName';
  public static readonly USERNAME_KEY: string = 'username';
  public static readonly GENDER_KEY: string = 'gender';
  public static readonly PASSWORD_KEY: string = 'password';
  public static readonly PHONE_KEY: string = 'phone';
  public static readonly EMAIL_KEY: string = 'email';
  public static readonly DISPLAYED_EMAIL_KEY: string = 'displayedEmail';
  public static readonly PROFILE_PHOTO_KEY: string = 'profilePhoto';
  public static readonly DEFAULT_ADDRESS_KEY: string = 'defaultAddress';

  public static readonly TEMP_PASSWORD: string = 'temp';

  public static readonly GENDER_MALE: string = 'male';
  public static readonly GENDER_FEMALE: string = 'female';

  constructor() {
    super(User.CLASS_NAME);
  }

  public static createUser(id: string) {
    let object = new this();
    object.id = id;
    return object;
  }

  public static current() {
    let user = super.current();

    if (!user) {
      return null;
    }

    if (!(user instanceof User)) {
      user['__proto__'] = User.prototype;
    }
    return user as User;
  }

  public get firstName(): string {
    return this.get(User.FIRST_NAME_KEY);
  }

  public set firstName(firstName: string) {
    this.set(User.FIRST_NAME_KEY, firstName);
  }

  public get lastName(): string {
    return this.get(User.LAST_NAME_KEY);
  }

  public set lastName(lastName: string) {
    this.set(User.LAST_NAME_KEY, lastName);
  }

  public get username(): string {
    return this.get(User.USERNAME_KEY);
  }

  public set username(username: string) {
    this.set(User.USERNAME_KEY, username);
  }

  public get gender(): string {
    return this.get(User.GENDER_KEY);
  }

  public set gender(gender: string) {
    this.set(User.GENDER_KEY, gender);
  }

  public get password(): string {
    return this.get(User.PASSWORD_KEY);
  }

  public set password(password: string) {
    this.set(User.PASSWORD_KEY, password);
  }

  public get phone(): string {
    return this.get(User.PHONE_KEY);
  }

  public set phone(phone: string) {
    this.set(User.PHONE_KEY, phone);
  }

  public get email(): string {
    let email = this.get(User.EMAIL_KEY);
    if (email) {
      return this.get(User.EMAIL_KEY);
    }
    return this.displayedEmail;
  }

  public set email(email: string) {
    this.set(User.EMAIL_KEY, email);
  }

  public get displayedEmail(): string {
    return this.get(User.DISPLAYED_EMAIL_KEY);
  }

  public set displayedEmail(displayedEmail: string) {
    this.set(User.DISPLAYED_EMAIL_KEY, displayedEmail);
  }

  public get profilePhoto(): FileRef {
    return this.get(User.PROFILE_PHOTO_KEY);
  }

  public set profilePhoto(profilePhoto: FileRef) {
    this.set(User.PROFILE_PHOTO_KEY, profilePhoto);
  }

  public get defaultAddress(): Address {
    return this.get(User.DEFAULT_ADDRESS_KEY);
  }

  public set defaultAddress(defaultAddress: Address) {
    this.set(User.DEFAULT_ADDRESS_KEY, defaultAddress);
  }

  public get fullName(): string {
    return (this.firstName || '') + ' ' + (this.lastName || '');
  }

  public get abbreviation(): string {
    let first = this.firstName ? this.firstName.slice(0, 1) : '';
    let last = (this.lastName) ? this.lastName.slice(0, 1) : '';
    return first + last;
  }

  public get jsonObject(): any {
    let object = {
                  'objectId': this.id,
                  'firstName': this.firstName,
                  'lastName': this.lastName,
                  'username': this.username,
                  'email': this.email,
                };
    if (!this.id) {
      object['password'] = this.password; //Math.random().toString(36).slice(-6);
    }
    return object;
  }

  public isUsernamePasswordUser(): boolean {
    return (this.username === this.displayedEmail);
  }

  public revert(): void {
    super['revert']();

    if (this.profilePhoto) {
      this.profilePhoto.revert();
    }

    if (this.defaultAddress) {
      this.defaultAddress.revert();
    }
  }
}

Parse.Object.registerSubclass(User.CLASS_NAME, User);
