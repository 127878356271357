
import { Injectable } from '@angular/core';

import { Order, User, Pagination, OrderStatus } from './../data';
import { OrderQuery } from './../queries';

import { WindowRef } from './window-ref';

@Injectable()
export class OrderService {
  constructor(private windowRef: WindowRef) {

  }

  public get(id: string) {
    return new OrderQuery()
        .includeItems()
        .include(Order.STATUS_KEY)
        .include(Order.PAYMENT_METHOD_KEY)
        .equalTo(Order.USER_KEY, User.current())
        .rx()
        .get(id);
  }

  public countOngoing() {
    return new OrderQuery()
      .notStatusCodes([OrderStatus.COMPLETED_STATUS, OrderStatus.COMPLETED_AND_VERIFIED_STATUS, OrderStatus.REFUNDED_STATUS, OrderStatus.CANCELLED_STATUS, OrderStatus.NONE_STATUS, OrderStatus.REJECTED_STATUS])
      .equalTo(Order.USER_KEY, User.current())
      .rx()
      .count()
  }

  public find(pagination?: Pagination) {
    let query = new OrderQuery().includeItems();

    if (pagination) {
      query.pagination(pagination);
    }

    return query.equalTo(Order.USER_KEY, User.current()).rx().find();
  }

  public export() {
    let promise = new Parse.Promise();

    Parse.Cloud.run('order-export', {}).then(
      result => {
        let href = 'data:application/pdf;base64,' + encodeURIComponent(result);

        this.windowRef.safeOpen(href, '_blank', 'orders.xlsx');

        promise.resolve(result);
      },
      error => {
        promise.reject(error);
      }
    );

    return promise;
  }

  public createInvoice(orderId) {
    let promise = new Parse.Promise();

    Parse.Cloud.run('order-create-invoice', {orderId: orderId}).then(
      result => {
        let href = 'data:application/pdf;base64,' + encodeURIComponent(result);

        this.windowRef.safeOpen(href, '_blank', 'invoice.pdf');

        promise.resolve(result);
      },
      error => {
        promise.reject(error);
      }
    );

    return promise;
  }
}
