import { Cascade } from './cascade';

import * as _ from 'lodash';

import { FileRef } from './file-ref';
import { MetaTags } from './meta-tags';
import { EventCategory } from './event-category';
import { EventGallery } from './event-gallery';

export class Event extends Cascade {
  public static readonly CLASS_NAME = 'Event';
  public static readonly TITLE_KEY = 'title';
  public static readonly SUBTITLE_KEY = 'subtitle';
  public static readonly VENUE_KEY = 'venue';
  public static readonly IMAGE_KEY = 'image';
  public static readonly GALLERIES_KEY = 'galleries';
  public static readonly CONTENT_KEY = 'content';
  public static readonly ELEMENTS_KEY = 'elements';
  public static readonly PERMALINK_KEY = 'permalink';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly START_DATE_KEY = 'startDate';
  public static readonly END_DATE_KEY = 'endDate';
  public static readonly ENABLED_KEY = 'enabled';
  public static readonly METATAGS_KEY = 'metaTags';
  public static readonly CATEGORY_KEY = 'category';

  constructor() {
    super(Event.CLASS_NAME);
  }

  public static create() {
    return _.assign(new Event(), { enabled: true, galleries: [EventGallery.create()]})
  }

  public get title(): string {
    return this.get(Event.TITLE_KEY);
  }

  public set title(title: string) {
    this.set(Event.TITLE_KEY, title);
  }

  public get venue(): string {
    return this.get(Event.VENUE_KEY);
  }

  public set venue(venue: string) {
    this.set(Event.VENUE_KEY, venue);
  }

  public get startDate(): Date {
    return this.get(Event.START_DATE_KEY);
  }

  public set startDate(date: Date) {
    this.set(Event.START_DATE_KEY, date);
  }

  public get endDate(): Date {
    return this.get(Event.END_DATE_KEY);
  }

  public set endDate(date: Date) {
    this.set(Event.END_DATE_KEY, date);
  }

  public get metaTags(): MetaTags {
    return this.get(Event.METATAGS_KEY);
  }

  public set metaTags(metaTags: MetaTags) {
    this.set(Event.METATAGS_KEY, metaTags);
  }

  public get category(): EventCategory {
    return this.get(Event.CATEGORY_KEY);
  }

  public set category(category: EventCategory) {
    this.set(Event.CATEGORY_KEY, category);
  }

  public get subtitle(): string {
    return this.get(Event.SUBTITLE_KEY);
  }

  public set subtitle(subtitle: string) {
    this.set(Event.SUBTITLE_KEY, subtitle);
  }

  public get image(): FileRef {
    return this.get(Event.IMAGE_KEY);
  }

  public set image(image: FileRef) {
    this.set(Event.IMAGE_KEY, image);
  }

  public get imageUrl(): string {
    return this.image && this.image.url ? this.image.url : ''
  }

  public get content(): string {
    return this.get(Event.CONTENT_KEY);
  }

  public set content(content: string) {
    this.set(Event.CONTENT_KEY, content);
  }

  public entries() {
    return _.compact([this, this.image]);
  }

  public get permalink(): string {
    return this.get(Event.PERMALINK_KEY);
  }

  public set permalink(permalink: string) {
    this.set(Event.PERMALINK_KEY, permalink);
  }

  public get sortOrder(): number {
    return this.get(Event.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(Event.SORT_ORDER_KEY, sortOrder);
  }

  public get enabled(): boolean {
    return this.get(Event.ENABLED_KEY);
  }

  public set enabled(enabled: boolean) {
    this.set(Event.ENABLED_KEY, enabled);
  }

  public get galleries(): EventGallery[] {
    return this.get(Event.GALLERIES_KEY)
  }

  public set galleries(galleries: EventGallery[]) {
    this.set(Event.GALLERIES_KEY, galleries)
  }
}

Parse.Object.registerSubclass(Event.CLASS_NAME, Event);
