import { ParseQuery } from './parse-query';

import { Page } from './../data';

export class PageQuery extends ParseQuery<Page> {
  constructor(pageId?: string) {
    super(Page);

    if (pageId) {
      this.equalTo(Page.OBJECT_ID_KEY, pageId);
    }
  }
}