import { ParseQuery } from './parse-query';

import { Attachment } from './../data';

export class AttachmentQuery extends ParseQuery<Attachment> {
  constructor() {
    super(Attachment);
  }

  public type(type: string) {
    this.equalTo(Attachment.TYPE_KEY, type);
    return this;
  }
}