import { Cascade } from './cascade';
import { ParseObject } from './parse-object';

import * as _ from 'lodash';

import { Collection } from './collection';
import { FileRef } from './file-ref';
import { Banner } from './banner';

export class BannerItem extends Cascade {
  public static readonly CLASS_NAME = 'BannerItem';
  public static readonly BANNER_KEY = 'banner';
  public static readonly COLLECTION_KEY = 'collection';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly LINK_KEY = 'link';
  public static readonly TITLE_KEY = 'title';
  public static readonly DESCRIPTION_KEY = 'desc';
  public static readonly IMAGE_KEY = 'image';

  constructor() {
    super(BannerItem.CLASS_NAME);
  }

  public static create() {
    const item = new BannerItem()
    return item;
  }

  public get banner(): Banner {
    return this.get(BannerItem.BANNER_KEY);
  }

  public set banner(banner: Banner) {
    this.set(BannerItem.BANNER_KEY, banner);
  }

  public get collection(): Collection {
    return this.get(BannerItem.COLLECTION_KEY);
  }

  public set collection(collection: Collection) {
    this.set(BannerItem.COLLECTION_KEY, collection);
  }

  public get sortOrder(): number {
    return this.get(BannerItem.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(BannerItem.SORT_ORDER_KEY, sortOrder);
  }

  public get link(): string {
    return this.get(BannerItem.LINK_KEY);
  }

  public set link(link: string) {
    this.set(BannerItem.LINK_KEY, link);
  }

  public get title(): string {
    return this.get(BannerItem.TITLE_KEY);
  }

  public set title(title: string) {
    this.set(BannerItem.TITLE_KEY, title);
  }

  public get description(): string {
    return this.get(BannerItem.DESCRIPTION_KEY);
  }

  public set description(description: string) {
    this.set(BannerItem.DESCRIPTION_KEY, description);
  }

  public get image(): FileRef {
    return this.get(BannerItem.IMAGE_KEY);
  }

  public set image(image: FileRef) {
    this.set(BannerItem.IMAGE_KEY, image);
  }

  public entries(): ParseObject[] {
    return _.compact([this.image, this]);
  }
}

Parse.Object.registerSubclass(BannerItem.CLASS_NAME, BannerItem);
