import { ParseQuery } from './parse-query';

import { UserQuery } from './user-query';

import { Order, User, OrderItem, Product, ProductVariant, OrderStatus } from '@shared/data';

export class OrderStatusQuery extends ParseQuery<OrderStatus> {
  constructor() {
    super(OrderStatus);
  }

  public code(code: string) {
    this.equalTo(OrderStatus.CODE_KEY, code);
    return this;
  }

  public codes(codes: string[]) {
    this.containedIn(OrderStatus.CODE_KEY, codes).addDescending(OrderStatus.CODE_KEY);
    return this;
  }

  public notCodes(codes: string[]) {
    this.notContainedIn(OrderStatus.CODE_KEY, codes);
    return this;
  }
}