import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CouponReport, Coupon, CouponStatus, User, CouponGroup, CheckoutDto, Pagination } from '@shared/data';
import { CouponQuery } from '@shared/queries';
import { WindowRef } from '@shared/services';

@Injectable()
export class CouponService {
  constructor(private windowRef: WindowRef) {

  }

  public verify(checkoutDto: CheckoutDto) {
    return Parse.Cloud.run('coupon-verify', checkoutDto);
  }

  // ===============================================================================================
  // Report Methods
  // ===============================================================================================

  public reportByDistributor() {
    return Parse.Cloud.run('coupon-report-by-distributor');
  }

  public reportByAdmin(distributorId: string, groupId: string) {
    return Parse.Cloud.run('coupon-report-by-admin', { distributorId: distributorId, groupId: groupId });
  }

  public reportByTeamLeader(distributorId: string, groupId: string) {
    return Parse.Cloud.run('coupon-report-by-team-leader', { distributorId: distributorId, groupId: groupId });
  }

  // ===============================================================================================
  // Search Methods
  // ===============================================================================================

  public searchByDistributor(searchText: string, statusCode: string, condition: number, pagination: Pagination) {
    let params = {
      searchText: searchText,
      statusCode: statusCode,
      condition: condition,
      pagination: pagination
    };

    return Parse.Cloud.run('coupon-search-by-distributor', params);
  }

  public searchByTeamLeader(searchText: string, distributorId: string, groupId: string, statusCode: string, condition: number, pagination: Pagination) {
    let params = {
      searchText: searchText,
      statusCode: statusCode,
      condition: condition,
      pagination: pagination,
      distributorId: distributorId,
      groupId: groupId
    };

    return Parse.Cloud.run('coupon-search-by-team-leader', params);
  }

  public searchByAdmin(searchText: string, distributorId: string, groupId: string, statusCode: string, condition: number, pagination: Pagination) {
    let params = {
      searchText: searchText,
      statusCode: statusCode,
      condition: condition,
      pagination: pagination,
      distributorId: distributorId,
      groupId: groupId
    };

    return Parse.Cloud.run('coupon-search-by-admin', params);
  }

  // ===============================================================================================
  // Export Methods
  // ===============================================================================================

  public exportByAdmin(fileName: string, distributorId: string, groupId: string, month: string, year: number) {
    const promise = new Parse.Promise();
    const params = { distributorId: distributorId, groupId: groupId, month: month, year: year }

    Parse.Cloud.run('coupon-export-by-admin', params).then(
      result => {
        let href = 'data:text/plain;base64,' + encodeURIComponent(result);

        this.windowRef.safeOpen(href, '_blank', fileName + '.xlsx');

        promise.resolve(result);
      },
      error => {
        promise.reject(error);
      }
    );

    return promise;
  }

  public exportByTeamLeader(fileName: string, distributorId: string, groupId: string, month: string, year: number) {
    const promise = new Parse.Promise();
    const params = { distributorId: distributorId, groupId: groupId, month: month, year: year }

    Parse.Cloud.run('coupon-export-by-team-leader', params).then(
      result => {
        let href = 'data:text/plain;base64,' + encodeURIComponent(result);

        this.windowRef.safeOpen(href, '_blank', fileName + '.xlsx');

        promise.resolve(result);
      },
      error => {
        promise.reject(error);
      }
    );

    return promise;
  }

  public exportByDistributor(fileName: string, month: string, year: number) {
    let promise = new Parse.Promise();

    Parse.Cloud.run('coupon-export-by-distributor', {month: month, year: year}).then(
      result => {
        let href = 'data:text/plain;base64,' + encodeURIComponent(result);

        this.windowRef.safeOpen(href, '_blank', fileName + '.xlsx');

        promise.resolve(result);
      },
      error => {
        promise.reject(error);
      }
    );

    return promise;
  }
}
