export class CouponCondition {
  public static readonly ANY = 0;
  public static readonly VALID = 1;
  public static readonly INVALID = 2;
  public static readonly EXPIRED = 3;
  
  public label: string;
  public id: number;
}

export const COUPON_CONDITIONS: CouponCondition[] = [
  {
    label: 'common.any',
    id: CouponCondition.ANY
  },
  {
    label: 'common.valid',
    id: CouponCondition.VALID
  },
  {
    label: 'common.invalid',
    id: CouponCondition.INVALID
  },
  {
    label: 'common.expired',
    id: CouponCondition.EXPIRED
  }
]