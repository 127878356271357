import { Injectable, ElementRef } from '@angular/core';

@Injectable()
export class WindowRef {
  public static readonly EXTRA_LARGE_WIDTH: number = 1199.98;
  public static readonly LARGE_WIDTH: number = 991.98;
  public static readonly MEDIUM_WIDTH: number = 767.98;
  public static readonly SMALL_WIDTH: number = 565.98;

  public isLarge: boolean;
  public isExtraLarge: boolean;
  public isMedium: boolean;
  public isSmall: boolean;
  public content: any;

  public get nativeWindow() {
    return window;
  }

  public open(url: string, target: string = "_tab") {
    window.open(url, target);
  }

  public safeOpen(url: string, target: string = "_tab", download?: string) {
    let a = document.createElement('a');

    a.href = url;
    a.target = target;

    if (download) {
      a.download = download;
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public reload(forcedReload: boolean = false) {
    window.location.reload(forcedReload);
  }
}
