import { Cascade } from './cascade';

import * as _ from 'lodash';

import { BannerItem } from './banner-item';
import { BannerOption } from './banner-option';

export class Banner extends Cascade {
  public static readonly CLASS_NAME = 'Banner';
  public static readonly ENABLED_KEY = 'enabled';
  public static readonly CODE_KEY = 'code';
  public static readonly ITEMS_KEY = 'items';
  public static readonly OPTION_KEY = 'option';

  constructor() {
    super(Banner.CLASS_NAME);
  }

  public static create() {
    const banner = new Banner()
    banner.items = [];
    banner.enabled = true;
    return banner
  }

  public get enabled(): boolean {
    return this.get(Banner.ENABLED_KEY);
  }

  public set enabled(enabled: boolean) {
    this.set(Banner.ENABLED_KEY, enabled);
  }

  public get items(): BannerItem[] {
    return this.get(Banner.ITEMS_KEY);
  }

  public set items(items: BannerItem[]) {
    this.set(Banner.ITEMS_KEY, items);
  }

  public get code(): string {
    return this.get(Banner.CODE_KEY);
  }

  public set code(code: string) {
    this.set(Banner.CODE_KEY, code);
  }

  public get option(): BannerOption {
    return this.get(Banner.OPTION_KEY);
  }

  public set option(option: BannerOption) {
    this.set(Banner.OPTION_KEY, option);
    this.code = option.code;
  }

  public entries() {
    let items = _.flatten(this.items.map(i => i.entries()));
    return _.concat(items, this);
  }
}

Parse.Object.registerSubclass(Banner.CLASS_NAME, Banner);
