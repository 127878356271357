import { Observable, Observer } from 'rxjs';

export class ParseObservable<T> extends Observable<T> {
  constructor(promise: Parse.Promise<T>) {
    super((observer: Observer<any>) => {
      promise.then(
        (result: T) => {
          observer.next(result);
          observer.complete();
        },
        error => {
          observer.error(error);
        }
      );
    });
  }
}
