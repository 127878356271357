import { ParseQuery } from './parse-query';

import '../extensions/reg-exp+escape';

import { EventCategory } from '../data';

export class EventCategoryQuery extends ParseQuery<EventCategory> {
  constructor(eventCatId?: string) {
    super(EventCategory);

    if (eventCatId) {
      this.equalTo(EventCategory.OBJECT_ID_KEY, eventCatId);
    }
  }

  public search(searchText: string) {
    if (searchText) {
      this.matches(EventCategory.NAME_KEY, RegExp.escape(searchText), 'i');
    }
  
    return this;
  }
}