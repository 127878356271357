import { ParseQuery } from './parse-query';

import { Configuration } from './../data';

export class ConfigurationQuery extends ParseQuery<Configuration> {
  constructor() {
    super(Configuration);
  }

  public common() {
    this.equalTo(Configuration.NAME_KEY, Configuration.NAME_COMMON);
    return this;
  }
}