import { ParseQuery } from './parse-query';

import { Event, EventCategory } from '../data';
import { EventCategoryQuery } from './event-category-query';

export class EventQuery extends ParseQuery<Event> {
  constructor(eventId?: string) {
    super(Event);

    if (eventId) {
      this.equalTo(Event.OBJECT_ID_KEY, eventId);
    }
  }

  public search(searchText: string) {
    if (searchText) {
      this.matches(Event.TITLE_KEY, RegExp.escape(searchText), 'i');
    }
   
    return this;
  }

  public upcoming() {
    this.greaterThanOrEqualTo(Event.START_DATE_KEY, new Date())
    return this
  }

  public category(id: string) {
    let queries: Parse.Query[] = []

    const parentCategory = new EventCategoryQuery(id)

    queries.push(new EventQuery().matchesQuery(Event.CATEGORY_KEY, parentCategory))
    queries.push(new EventQuery().matchesQuery(Event.CATEGORY_KEY, new EventCategoryQuery().matchesQuery(EventCategory.PARENT_KEY, parentCategory)))

    this.orQuery(queries)
    
    return this
  }
}