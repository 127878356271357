import { ParseObject } from './parse-object';

import { User } from './user'
import { Product } from './product'

export class Favourite extends ParseObject {
  public static CLASS_NAME = 'Favourite'
  public static PRODUCT_KEY = 'product'
  public static OWNER_KEY = 'owner'

  constructor() {
    super(Favourite.CLASS_NAME)
  }

  public static create(owner: User, product: Product) {
    const fav = new Favourite()

    fav.owner = owner
    fav.product = product

    return fav
  }

  public get owner(): User {
    return this.get(Favourite.OWNER_KEY)
  }

  public set owner(owner: User) {
    this.set(Favourite.OWNER_KEY, owner)
  }

  public get product(): Product {
    return this.get(Favourite.PRODUCT_KEY)
  }

  public set product(product: Product) {
    this.set(Favourite.PRODUCT_KEY, product)
  }
}

Parse.Object.registerSubclass(Favourite.CLASS_NAME, Favourite)
