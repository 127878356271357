import { Injectable } from '@angular/core';

import { Observable, Observer } from 'rxjs';

import { WindowRef } from './window-ref';

@Injectable()
export class CustomerService {
  constructor(private windowRef: WindowRef) {

  }

  public export() {
    let promise = new Parse.Promise();

    Parse.Cloud.run('customer-export', {}).then(
      result => {
        let href = 'data:text/plain;base64,' + encodeURIComponent(result);

        this.windowRef.safeOpen(href, '_blank', 'customers.xlsx');

        promise.resolve(result);
      },
      error => {
        promise.reject(error);
      }
    )

    return promise;
  }
}
