import { ParseQuery } from './parse-query';

import { UserQuery } from './user-query';
import { Order, OrderProduct } from '@shared/data';

export class OrderProductQuery extends ParseQuery<OrderProduct> {
  constructor(orderProductId?: string) {
    super(OrderProduct);

    if (orderProductId) {
      this.equalTo(OrderProduct.OBJECT_ID_KEY, orderProductId);
    }
  }

  public orderId(orderId: string) {
    this.equalTo(OrderProduct.ORDER_KEY, new Order(orderId));
    return this;
  }

  public search(searchText: string) {
    this.searchKey(OrderProduct.PRODUCT_NAME_KEY, searchText);
    return this;
  }
}