import { OrderStatus } from '@shared/data';
import { ParseObject } from './parse-object';

export class DeliveryStatus extends ParseObject {
  public static readonly CLASS_NAME = 'DeliveryStatus';
  public static readonly NAME_KEY = 'name';
  public static readonly CODE_KEY = 'code';

  public static readonly READY_TO_PICKUP_STATUS = 'readyToPickup';
  public static readonly DELIVERING_STATUS = 'delivering';
  public static readonly COMPLETED_STATUS = 'completed';
  public static readonly CANCELLED_STATUS = 'cancelled';

  constructor() {
    super(DeliveryStatus.CLASS_NAME);
  }

  public get name(): string {
    return this.get(DeliveryStatus.NAME_KEY);
  }

  public set name(name: string) {
    this.set(DeliveryStatus.NAME_KEY, name);
  }

  public get code(): string {
    return this.get(DeliveryStatus.CODE_KEY);
  }

  public set code(code: string) {
    this.set(DeliveryStatus.CODE_KEY, code);
  }

  public static deliveryStatusCodeFromOrderStatus(orderStatus: OrderStatus): string {
    if (orderStatus.code == OrderStatus.DELIVERING_STATUS) {
      return DeliveryStatus.DELIVERING_STATUS;
    }
    else if (orderStatus.code == OrderStatus.COMPLETED_STATUS || orderStatus.code == OrderStatus.COMPLETED_AND_VERIFIED_STATUS) {
      return DeliveryStatus.COMPLETED_STATUS;
    }
    else if (orderStatus.code == OrderStatus.CANCELLED_STATUS || orderStatus.code == OrderStatus.REFUNDED_STATUS) {
      return DeliveryStatus.CANCELLED_STATUS;
    }

    return DeliveryStatus.READY_TO_PICKUP_STATUS;
  }
}

Parse.Object.registerSubclass(DeliveryStatus.CLASS_NAME, DeliveryStatus);
