import { ProductQuery } from './../queries/product-query';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';

// import { Locker } from 'angular-safeguard';
import { Observable, Observer, BehaviorSubject, Subscriber, of } from 'rxjs';
import { switchMap, tap ,first, flatMap, map, merge, publishReplay, refCount } from 'rxjs/operators';

import { LocalStorageService } from './local-storage-service';
import { UserService } from './user-service';
import { ShoppingCartQuery, CategoryQuery } from '@shared/queries';
import { ShoppingCart, ShoppingCartItem, User, Product, ProductVariant, ParseObject, Pagination } from './../data';


@Injectable()
export class ShoppingCartService {
  private _shoppingCart: Observable<ShoppingCart>;

  constructor(private localStorageService: LocalStorageService, private userService: UserService) {
    
  }

  // ===============================================================================================
  // Accessors
  // ===============================================================================================

  public get shoppingCart() {
    if (!this._shoppingCart) {
      this._shoppingCart = this.userService.user.pipe(switchMap(
        user => {
          let localShoppingCart = this.localStorageService.shoppingCart || new ShoppingCart();

          if (user) {
            let query = new ShoppingCartQuery()
              .current()
              .include(ShoppingCart.ITEMS_KEY)
              .include([ShoppingCart.ITEMS_KEY, ShoppingCartItem.PRODUCT_KEY].join('.'))
              .include([ShoppingCart.ITEMS_KEY, ShoppingCartItem.PRODUCT_KEY, Product.VARIANTS_KEY].join('.'))
              .include([ShoppingCart.ITEMS_KEY, ShoppingCartItem.PRODUCT_KEY, Product.VARIANTS_KEY, ProductVariant.IMAGES_KEY].join('.'))
              .include([ShoppingCart.ITEMS_KEY, ShoppingCartItem.PRODUCT_KEY, Product.DEFAULT_VARIANT_KEY].join('.'))
              .include([ShoppingCart.ITEMS_KEY, ShoppingCartItem.PRODUCT_KEY, Product.DEFAULT_VARIANT_KEY, ProductVariant.IMAGES_KEY].join('.'));

            return query.rx().first().pipe(switchMap(
              value => {
                if (value) {
                    if (localShoppingCart && localShoppingCart.items.length) {

                    localShoppingCart.items.forEach(i => {
                      let exist = value.items.find(o => o.same(i));

                      if (exist) {
                        exist.quantity += i.quantity;
                      } else {
                        i.user = user;
                        value.add(ShoppingCart.ITEMS_KEY, i);
                      }
                    });

                    let result = value.rx().save().pipe(
                      merge(this.fetch(value))).pipe(
                      tap(() => {
                        this.localStorageService.shoppingCart = null;
                      }));


                    result.pipe(first()).subscribe(
                      v => {
                        
                      },
                      error => {
                        
                      }
                    )

                    return result;
                  }

                  return of(value);
                } else {
                  localShoppingCart.user = user;

                  return this.fetch(localShoppingCart)
                    
                    .pipe(merge(localShoppingCart.rx().save())).pipe(
                      tap(() => {
                      this.localStorageService.shoppingCart = null;
                    }));
                }
              }
            ))
          } else {
            return this.fetch(localShoppingCart)
          }
        }
      )).pipe(publishReplay(1)).pipe(refCount());
    }
    return this._shoppingCart;
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  public addItem(item: ShoppingCartItem) {
    return this.shoppingCart.pipe(flatMap(
      value => {
        let exist = value.items.find(o => {
          const matched = _.isEqual(o.inputOptions, item.inputOptions)

          return o.variant.id == item.variant.id && o.product.id == item.product.id && matched
        });

        if (exist) {
          exist.quantity += item.quantity;
        } else {
          item.user = User.current();
          value.add(ShoppingCart.ITEMS_KEY, item);
        }

        return this.save(value);
      }
    )).pipe(first());
  }

  public removeItem(item: ShoppingCartItem) {
    return this.shoppingCart.pipe(flatMap(
      value => {
        value.remove(ShoppingCart.ITEMS_KEY, item);
        item.destroy();
        return this.save(value);
      }
    )).pipe(first());
  }

  public removeMany(items: ShoppingCartItem[]) {
    return this.shoppingCart.pipe(flatMap(
      value => {
        _.forEach(items, (o => {
          value.remove(ShoppingCart.ITEMS_KEY, o)
        }))
        // value.remove(ShoppingCart.ITEMS_KEY, items);
        ShoppingCartItem.destroyAll(items);
        return this.save(value);
      }
    )).pipe(first());
  }

  public removeAll() {
    return this.shoppingCart.pipe(flatMap(
      value => {
        value.items = [];
        ShoppingCartItem.destroyAll(value.items);
        return this.save(value);
      }
    )).pipe(first());
  }

  // ===============================================================================================
  // Private Methods
  // ===============================================================================================

  private save(shoppingCart: ShoppingCart) {
    if (shoppingCart.user) {
      return shoppingCart.rx().save();
    }

    this.localStorageService.shoppingCart = shoppingCart;

    return of(shoppingCart);
  }

  private fetch(shoppingCart: ShoppingCart) {
    let productIds = shoppingCart.items.map(o => o.product.id);

    return new ProductQuery()
      .includeVariants()
      .includeDefaultVariant()
      .containedIn(Product.OBJECT_ID_KEY, productIds)
      .rx()
      .find().pipe(map(o => shoppingCart))
  }
}
