import { ParseObject } from './parse-object';

export class Configuration extends ParseObject {
  public static readonly CLASS_NAME = 'Configuration';
  public static readonly NAME_KEY = 'name';
  public static readonly VALUE_KEY = 'value';

  public static readonly NAME_COMMON = 'common';
  
  constructor() {
    super(Configuration.CLASS_NAME);
  }

  public get name(): string {
    return this.get(Configuration.NAME_KEY);
  }

  public set name(name: string) {
    this.set(Configuration.NAME_KEY, name);
  }

  public get value(): any {
    return this.get(Configuration.VALUE_KEY);
  }

  public set value(value: any) {
    this.set(Configuration.VALUE_KEY, value);
  }

  public op(key) {
    return this.value[key] || '';
  }
}

Parse.Object.registerSubclass(Configuration.CLASS_NAME, Configuration);