import { Observable, Observer, Subscriber } from 'rxjs';

import { ParseObject } from './../data';

export class RxQuery<T extends Parse.Object> {
  private query: Parse.Query<T>;

  constructor(query: Parse.Query<T>) {
    this.query = query;
  }

  public get(id: string) {
    return new Observable<T>((o: Subscriber<any>) => {
      this.query.get(id).then(
        result => {
          o.next(result);
          o.complete();
        },
        error => {
          o.error(error);
        }
      )
    });
  }

  public find() {
    return new Observable<T[]>((o: Subscriber<any>) => {
      this.query.find().then(
        result => {
          o.next(result);
          o.complete();
        },
        error => {
          o.error(error);
        }
      )
    });
  }

  public first() {
    return new Observable<T>((o: Subscriber<any>) => {
      this.query.first().then(
        result => {
          o.next(result);
          o.complete();
        },
        error => {
          o.error(error);
        }
      )
    });
  }

  public count() {
    return new Observable<number>((o: Subscriber<any>) => {
      this.query.count().then(
        result => {
          o.next(result);
          o.complete();
        },
        error => {
          o.error(error);
        }
      )
    });
  }
}

export class RxObject<T> {
  private object: Parse.Object;

  constructor(object: Parse.Object) {
    this.object = object;
  }

  public save(attr?: {[key: string]: any}) {
    return new Observable<T>((o: Subscriber<any>) => {
      this.object.save(attr).then(
        result => {
          o.next(result);
          o.complete();
        },
        error => {
          o.error(error);
        }
      )
    });
  }

  public destroy() {
    return new Observable<T>((o: Subscriber<any>) => {
      this.object.destroy().then(
        result => {
          o.next(result);
          o.complete();
        },
        error => {
          o.error(error);
        }
      )
    });
  }

  public fetch() {
    return new Observable<T>((o: Subscriber<any>) => {
      this.object.fetch().then(
        result => {
          o.next(result);
          o.complete();
        },
        error => {
          o.error(error);
        }
      )
    });
  }
}

declare global {
  namespace Parse {
    interface Object {
      rx(): RxObject<this>;
    }

    interface Query<T extends Parse.Object> {
      rx(): RxQuery<T>;
      subscribe(): any;
    }
  }
}

Parse.Object.prototype.rx = function() {
  if (!this._rx) {
    this._rx = new RxObject(this);
  }
  return this._rx;
}

Parse.Query.prototype.rx = function() {
  if (!this._rx) {
    this._rx = new RxQuery(this);
  }
  return this._rx;
}
