import { ParseQuery } from './parse-query';

import './../extensions/reg-exp+escape';

import { Tutorial } from './../data';

export class TutorialQuery extends ParseQuery<Tutorial> {
  constructor() {
    super(Tutorial.CLASS_NAME);
  }
}