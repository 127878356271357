import * as _ from 'lodash';

import { FileRef } from './file-ref';
import { ParseObject } from './parse-object';
import { EventGalleryItem } from './event-gallery-item';

export class EventGallery extends ParseObject {
  public static readonly CLASS_NAME = 'EventGallery';
  public static readonly TITLE_KEY = 'title';
  public static readonly ITEMS_KEY = 'items';
  public static readonly ENABLED_KEY = 'enabled';
  public static readonly SORT_ORDER_KEY = 'sortOrder';

  constructor() {
    super(EventGallery.CLASS_NAME);
  }

  public static create() {
    const gallery = new EventGallery()
    gallery.title = ''
    gallery.items = []
    gallery.enabled = true
    return gallery
  }

  public get title(): string {
    return this.get(EventGallery.TITLE_KEY);
  }

  public set title(title: string) {
    this.set(EventGallery.TITLE_KEY, title);
  }

  public get enabled(): boolean {
    return this.get(EventGallery.ENABLED_KEY);
  }

  public set enabled(enabled: boolean) {
    this.set(EventGallery.ENABLED_KEY, enabled);
  }

  public get sortOrder(): number {
    return this.get(EventGallery.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(EventGallery.SORT_ORDER_KEY, sortOrder);
  }

  public get items(): EventGalleryItem[] {
    return this.get(EventGallery.ITEMS_KEY);
  }

  public set items(image: EventGalleryItem[]) {
    this.set(EventGallery.ITEMS_KEY, image);
  }

}

Parse.Object.registerSubclass(EventGallery.CLASS_NAME, EventGallery);
