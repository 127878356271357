import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { map, switchMap } from 'rxjs/operators';

import { Category } from './../data';

interface BrandResult {
  rows: Category[];
  children: object;
}

@Injectable()
export class BrandService {
  private _find: Observable<BrandResult>;
  private _mains: Observable<Category[]>;
  private _children: Observable<object>;
  private _firstMain: Observable<Category>;
  private _secondMain: Observable<Category>;
  private _thirdMain: Observable<Category>;

  constructor() {

  }

  public async subs(brandId: string, menuId: string) {
    return await Parse.Cloud.run('tem-subbrand-find', { brandId, menuId })
  }

  public find() {
    if (!this._find) {
      this._find = fromPromise(Parse.Cloud.run('tem-brand-find'))
    }
    return this._find
  }

  public mains() {
    if (!this._mains) {
      this._mains = this.find().pipe(switchMap(value => {
        return Observable.of(value.rows).publishReplay(1).refCount()
      }))
    }
    return this._mains;
  }

  // public children() {
  //   if (!this._children) {
  //     this._children = this.find().switchMap(value => {
  //       return Observable.of(value.children).publishReplay(1).refCount()
  //     })
  //   }
  //   return this._children;
  // }

  public firstMain() {
    if (!this._firstMain) {
      this._firstMain = this.mains().pipe(map(c => c[0]));
    }
    return this._firstMain;
  }

  public secondMain() {
    if (!this._secondMain) {
      this._secondMain = this.mains().pipe(map(c => c[1]));
    }
    return this._secondMain;
  }

  public thirdMain() {
    if (!this._thirdMain) {
      this._thirdMain = this.mains().pipe(map(c => c[2]));
    }
    return this._thirdMain;
  }
}
