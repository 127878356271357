import { ParseQuery } from './parse-query';

import { FileRef, Filter } from './../data';

export class FileRefQuery extends ParseQuery<FileRef> {
  constructor() {
    super(FileRef);
  }

  public filter(filter: Filter) {
    switch (filter.type) {
      case FileRef.PATH_KEY:
        if (filter.value) {
          this.equalTo(FileRef.PATH_KEY, filter.value);
        }
        break;
    }
    return this;
  }
}