import { Cascade } from './cascade';

import * as _ from 'lodash';
import { Product } from './product';
import { ParseObject } from './parse-object';

export class FeatureProduct extends ParseObject {
  public static readonly CLASS_NAME = 'FeatureProduct';
  public static readonly PRODUCT_KEY = 'product';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly CATEGORY_KEY = 'category';

  constructor() {
    super(FeatureProduct.CLASS_NAME)
  }

  public static create() {
    const item = new FeatureProduct()
    return item;
  }

  set product(product: Product) {
    this.set(FeatureProduct.PRODUCT_KEY, product)
  }

  get product(): Product {
    return this.get(FeatureProduct.PRODUCT_KEY)
  }

  get category() {
    return this.get(FeatureProduct.CATEGORY_KEY);
  }

  set category(category) {
    this.set(FeatureProduct.CATEGORY_KEY, category);
  }

  get sortOrder() {
    return this.get(FeatureProduct.SORT_ORDER_KEY);
  }

  set sortOrder(sortOrder: number) {
    this.set(FeatureProduct.SORT_ORDER_KEY, sortOrder);
  }

}

Parse.Object.registerSubclass(FeatureProduct.CLASS_NAME, FeatureProduct);


