import { City } from './city';
import { ParseObject } from './parse-object';
import { PaymentMethod } from './payment-method';

export interface PriceRange {
  minRange: number;
  price: number;
}

export class ShippingZone extends ParseObject {
  public static readonly CLASS_NAME = 'ShippingZone';
  public static readonly CITY_KEY = 'city';
  public static readonly PRICE_KEY = 'price';
  public static readonly MIN_DAYS_KEY = 'minDays';
  public static readonly MAX_DAYS_KEY = 'maxDays';
  public static readonly ENABLED_KEY = 'enabled';
  public static readonly PRICE_RANGES_KEY = 'priceRanges';
  public static readonly PAYMENT_OPTIONS_KEY = 'paymentOptions';

  constructor() {
    super(ShippingZone.CLASS_NAME);
  }

  public get city(): City {
    return this.get(ShippingZone.CITY_KEY);
  }

  public set city(city: City) {
    this.set(ShippingZone.CITY_KEY, city);
  }

  public get price(): number {
    return this.get(ShippingZone.PRICE_KEY);
  }

  public set price(price: number) {
    this.set(ShippingZone.PRICE_KEY, price);
  }

  public get minDays(): number {
    return this.get(ShippingZone.MIN_DAYS_KEY);
  }

  public set minDays(minDays: number) {
    this.set(ShippingZone.MIN_DAYS_KEY, minDays);
  }

  public get maxDays(): number {
    return this.get(ShippingZone.MAX_DAYS_KEY);
  }

  public set maxDays(maxDays: number) {
    this.set(ShippingZone.MAX_DAYS_KEY, maxDays);
  }

  public get enabled(): boolean {
    return this.get(ShippingZone.ENABLED_KEY);
  }

  public set enabled(enabled: boolean) {
    this.set(ShippingZone.ENABLED_KEY, enabled);
  }

  public get priceRanges(): PriceRange[] {
    return this.get(ShippingZone.PRICE_RANGES_KEY);
  }

  public set priceRanges(priceRanges: PriceRange[]) {
    this.set(ShippingZone.PRICE_RANGES_KEY, priceRanges);
  }

  public get paymentOptions(): any {
    return this.get(ShippingZone.PAYMENT_OPTIONS_KEY)
  }

  public set paymentOptions(paymentOptions: any) {
    this.set(ShippingZone.PAYMENT_OPTIONS_KEY, paymentOptions);
  }
}

Parse.Object.registerSubclass(ShippingZone.CLASS_NAME, ShippingZone);
