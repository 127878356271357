import { Product } from './product';

export class ProductSnapshot {
  public name: string;
  public detail: string;
  public description: string;
  public requiredSerialNumber: boolean;

  constructor(product: Product) {
    this.name = product.name;
    this.detail = product.detail;
    this.description = product.description;
    this.requiredSerialNumber = product.requiredSerialNumber;
  }
}