import * as _ from 'lodash';

import { ParseObject } from './parse-object';

import { User } from './user';
import { Product } from './product';
import { ProductOption } from './product-option';
import { ProductVariant } from './product-variant';
import { SerialNumberInfo } from './serial-number-info';
import { ProductVariantSnapshot } from './product-variant-snapshot';
import { ProductSnapshot } from './product-snapshot';

export class OrderItem extends ParseObject {
  public static readonly CLASS_NAME = 'OrderItem';
  public static readonly PRICE_KEY = 'price';
  public static readonly QUANTITY_KEY = 'quantity';
  public static readonly PRODUCT_KEY = 'product';
  public static readonly VARIANT_KEY = 'variant';
  public static readonly IMAGE_KEY = 'image';
  public static readonly PRODUCT_SNAPSHOT_KEY = 'productSnapshot';
  public static readonly VARIANT_SNAPSHOT_KEY = 'variantSnapshot';
  public static readonly USER_KEY = 'user';
  public static readonly SERIAL_NUMBER_INFO_KEY = 'serialNumberInfo';
  public static readonly INPUT_OPTIONS_KEY = 'inputOptions';

  constructor() {
    super(OrderItem.CLASS_NAME);
  }

  public get user() {
    return this.get(OrderItem.USER_KEY);
  }

  public set user(user: User) {
    this.set(OrderItem.USER_KEY, user);
  }

  public get quantity() {
    return this.get(OrderItem.QUANTITY_KEY);
  }

  public set quantity(quantity: number) {
    this.set(OrderItem.QUANTITY_KEY, quantity);
  }

  public get product() {
    return this.get(OrderItem.PRODUCT_KEY);
  }

  public set product(product: Product) {
    this.set(OrderItem.PRODUCT_KEY, product);
  }

  public get variant() {
    return this.get(OrderItem.VARIANT_KEY);
  }

  public set variant(variant: ProductVariant) {
    this.set(OrderItem.VARIANT_KEY, variant);
  }

  public get price() {
    return this.get(OrderItem.PRICE_KEY);
  }

  public set price(price: number) {
    this.set(OrderItem.PRICE_KEY, price);
  }

  public get productSnapshot() {
    return this.get(OrderItem.PRODUCT_SNAPSHOT_KEY);
  }

  public set productSnapshot(productSnapshot: ProductSnapshot) {
    this.set(OrderItem.PRODUCT_SNAPSHOT_KEY, productSnapshot);
  }

  public get variantSnapshot() {
    return this.get(OrderItem.VARIANT_SNAPSHOT_KEY);
  }

  public set variantSnapshot(variantSnapshot: ProductVariantSnapshot) {
    this.set(OrderItem.VARIANT_SNAPSHOT_KEY, variantSnapshot);
  }

  public get serialNumberInfo() {
    return this.get(OrderItem.SERIAL_NUMBER_INFO_KEY);
  }

  public set serialNumberInfo(serialNumberInfo: SerialNumberInfo) {
    this.set(OrderItem.SERIAL_NUMBER_INFO_KEY, serialNumberInfo);
  }

  public get inputOptions() {
    return this.get(OrderItem.INPUT_OPTIONS_KEY);
  }

  public set inputOptions(inputOptions: any) {
    this.set(OrderItem.INPUT_OPTIONS_KEY, inputOptions);
  }

  public get totalPrice() {
    return this.price * this.quantity;
  }

  public get available() {
    return this.product && this.product.available && this.variant && this.variant.available;
  }

  public values() {
    let properties = this.available ? this.variant.properties : this.variantSnapshot ? this.variantSnapshot.properties : {};
    let colorTitle: string = ''

    this.productSnapshot['tabs'].forEach(o => {
      if(o.key == 'color') {
        colorTitle = o.title
      }

      else { colorTitle = 'Color' }
    });

    return _.keys(properties).map(k => {
      if (k == 'Color')
      return {
        key: colorTitle,
        value: properties[k]
      };

      else return {
        key: k,
        value: properties[k]
      };
    });
  }

  public image() {
    let color = this.variant.color;
    let variantImage = this.product ? this.product.firstImage(ProductOption.COLOR, color) : null;
    let defaultVariantImage = this.product ? this.product.defaultVariant.firstImage : null;
    return variantImage || defaultVariantImage;
  }
}

Parse.Object.registerSubclass(OrderItem.CLASS_NAME, OrderItem);