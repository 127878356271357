//import * as Parse from 'parse';

import { ParseObject } from './parse-object';

export class Language extends ParseObject {
  public static readonly CLASS_NAME = 'Language';
  public static readonly CODE_KEY = 'code';
  public static readonly ENGLISH = 'en';

  constructor() {
    super(Language.CLASS_NAME);
  }

  public get code(): string {
    return this.get(Language.CODE_KEY);
  }

  public set code(code: string) {
    this.set(Language.CODE_KEY, code);
  }
}

Parse.Object.registerSubclass(Language.CLASS_NAME, Language);
