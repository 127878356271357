import * as _ from 'lodash';

import { Product } from './product';
import { ProductVariant } from './product-variant';

export class ProductAdjustment {
  public static readonly ADD_TYPE = 'add'
  public static readonly SET_TYPE = 'set'
  
  public currentQuantity: number;
  public variant: ProductVariant;

  private _type: string;
  
  // ===============================================================================================
  // Private Methods
  // ===============================================================================================

  public static create(variant: ProductVariant, type: string) {
    const adjustment = new ProductAdjustment()
    
    adjustment.variant = variant
    adjustment.type = type;
    adjustment.currentQuantity = variant.quantity || 0;

    return adjustment
  }
  
  public static adjustments(variants: ProductVariant[]) {
    const adjustments = _.reduce(variants, (current, prev) => {
      current[prev.adjustmentId] = ProductAdjustment.create(prev, this.ADD_TYPE)
      return current;
    }, {})

    return adjustments;
  }

  // ===============================================================================================
  // Setters & Getter
  // ===============================================================================================

  public set type(type: string) {
    const temp = this.quantity;

    this._type = type;

    this.quantity = temp
  }

  public get type() {
    return this._type;
  }

  public get quantity() {
    return this.type == ProductAdjustment.ADD_TYPE ? (this.variant.quantity || 0) - this.currentQuantity : this.variant.quantity
  }

  public set quantity(quantity: number) {
    if (this.type == ProductAdjustment.ADD_TYPE) {
      this.variant.quantity = (this.currentQuantity || 0) + quantity
    } else {
      this.variant.quantity = quantity
    }
  }

  public get value() {
    return this.variant.quantity - this.currentQuantity
  }
}