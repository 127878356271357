import { ParseObject } from './parse-object';

import { FileRef } from './file-ref';

export class Tutorial extends ParseObject {
  public static readonly CLASS_NAME = 'Tutorial';
  public static readonly TITLE_KEY = 'title';
  public static readonly SUBTITLE_KEY = 'subtitle';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly IMAGE_KEY = 'image';

  constructor() {
    super(Tutorial.CLASS_NAME);
  }

  public get title(): string {
    return this.get(Tutorial.TITLE_KEY);
  }

  public set title(title: string) {
    this.set(Tutorial.TITLE_KEY, title);
  }

  public get subtitle(): string {
    return this.get(Tutorial.SUBTITLE_KEY);
  }

  public set subtitle(subtitle: string) {
    this.set(Tutorial.SUBTITLE_KEY, subtitle);
  }

  public get sortOrder(): number {
    return this.get(Tutorial.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(Tutorial.SORT_ORDER_KEY, sortOrder);
  }

  public get image(): FileRef {
    return this.get(Tutorial.IMAGE_KEY);
  }

  public set image(image: FileRef) {
    this.set(Tutorial.IMAGE_KEY, image);
  }
}

Parse.Object.registerSubclass(Tutorial.CLASS_NAME, Tutorial);
