import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { Observable, Observer, Subscriber } from 'rxjs';

import { ShoppingCartService } from './shopping-cart-service';
import { CityQuery } from './../queries';
import { Address, Order, User, ShoppingCartItem, ShippingInfo, City } from './../data';

@Injectable()
export class CheckoutService {
  public step: number;
  public items: ShoppingCartItem[];
  public order: Order;
  public shippingInfo: ShippingInfo;
  public discount: number;
  public couponCode: string;

  private _address: Address;

  constructor(private shoppingCartService: ShoppingCartService) {
    this.items = [];
  }

  public updateShippingInfo() {
    let promise = new Parse.Promise<ShippingInfo>();

    if (!this.address) {
      promise.reject(new Parse.Error(101, 'Address is undefined'));
      return promise;
    }

    let query = new CityQuery().equalTo(City.NAME_KEY, this.address.city);

    query.first().then(
      city => {
        Parse.Cloud.run('getShippingInfo', { totalPrice: this.itemsTotal - (this.discount || 0), cityId: city.id }).then(
          shippingInfo => {
            this.shippingInfo = shippingInfo;
            promise.resolve(shippingInfo)
          },
          error => {
            this.shippingInfo = null
            promise.fail(error);
          }
        )
      },
      error => {
        promise.fail(error);
      }
    )

    return promise;
  }

  public get totalPrice() {
    return this.itemsTotal + this.shippingFee - (this.discount || 0);
  }

  public get itemsTotal() {
    return _.sumBy(this.items, o => o.totalPrice);
  }

  public get itemQuantity() {
    return this.items.length;
  }

  public get shippingFee() {
    return this.shippingInfo ? this.shippingInfo.shippingFee : 0;
  }

  public get address() {
    let user = User.current();

    return this._address || (user ? user.defaultAddress : null);
  }

  public set address(address: Address) {
    this._address = address;
  }

  public get notEmpty() {
    return this.items && this.items.length && this.items.some(o => o['checked'])
  }

  public get available() {
    return this.items.every(o => o.available)
  }

  public get inStock() {
    return this.items.every(o => o.variant.quantity > 0);
  }

  public get validQuantity() {
    return this.items.every(o => o.quantity > 0 && o.quantity <= o.variant.quantity) && this.items.every(o => o.quantity % 1 === 0);
  }

  public get requiredCoupon() {
    return this.items.some(o => o.product.requiredCoupon == true)
  }
}
