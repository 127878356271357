import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';

@Injectable()
export class ProductViewService {
  constructor() {

  }

  public productView() {
    return fromPromise(Parse.Cloud.run('product-view-find', { limit: 15 })).publishReplay(1).refCount();
  }
}
