import { Injectable } from '@angular/core';

import { Observable, Observer, Subscriber } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

import { Page } from './../data';
import { PageQuery } from './../queries';

@Injectable()
export class PageService {
  private _find: Observable<Page[]>;
  private _contactUs: Observable<Page>;
  private _aboutUs: Observable<Page>;

  constructor() {

  }

  public find() {
    if (!this._find) {
      this._find = new PageQuery()
        .include(Page.IMAGE_KEY)
        .addAscending(Page.SORT_ORDER_KEY)
        .rx()
        .find();
    }
    return this._find;
  }

  public contactUs() {
    if (!this._contactUs) {
      this._contactUs = this.permalink(Page.PERMALINK_CONTACT_US).pipe(publishReplay(1)).pipe(refCount());
    }
    return this._contactUs;
  }

  public aboutUs() {
    if (!this._aboutUs) {
      this._aboutUs = this.permalink(Page.PERMALINK_ABOUT_US).pipe(publishReplay(1)).pipe(refCount());
    }
    return this._aboutUs;
  }

  public permalink(permalink: string) {
    return new PageQuery()
      .include(Page.IMAGE_KEY)
      .equalTo(Page.PERMALINK_KEY, permalink)
      .rx()
      .first();
  }
}
