import { Injectable } from '@angular/core';

import { Observable, Observer, Subscriber } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import * as _ from 'lodash';

import './../extensions/parse+rx';

import { ParseObservable } from './parse-observable';
import { Product, Collection, ProductVariant, Category, ProductInput, ProductType } from './../data';
import { ProductQuery } from './../queries';
import { CollectionService } from './collection-service';
import { CategoryService } from './category-service';
import { BrandService } from './brand-service';
import { publishReplay, refCount, switchMap } from 'rxjs/operators';
import { FeatureProductService } from './feature-product-service';

const PRODUCT_VIEW = 'product-view';

interface ProductResult {
  rows: Product[];
  count: number;
  selected: Category
}

@Injectable()
export class ProductService {
  private _findPopularProducts: Observable<ProductResult>
  private _mostPopular: Observable<Product[]>;
  private _firstMain: Observable<Product[]>;
  private _secondMain: Observable<Product[]>;
  private _thirdMain: Observable<Product[]>;
  private _newProduct: Observable<Product[]>;


  constructor(
    private brandService: BrandService,
    public categoryService: CategoryService,
    public featureProductService: FeatureProductService) {
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  public get(id: string) {
    let query = new ProductQuery()
      .includeAttachments()
      .includeVariants()
      .includeAttachments()
      .includeDefaultVariant()
      .enabled()
      .include([Product.INPUTS_KEY, ProductInput.UNITS_KEY].join('.'));

    return query.rx().get(id);
  }

  public findPopularProducts() {
    if (!this._findPopularProducts) {
      this._findPopularProducts = fromPromise(Parse.Cloud.run('product-popular', { limit: 15, type: ProductType.MAIN }))
    }
    return this._findPopularProducts
  }


  public mostPopular() {
    if (!this._mostPopular) {

      this._mostPopular = this.findPopularProducts().pipe(switchMap(value => {
        return Observable.of(value.rows).publishReplay(1).refCount()
      }))
    }
    return this._mostPopular;
  }

  public newProduct() {
    if (!this._newProduct) {
      let query = new ProductQuery()
        .includeDefaultVariant()
        .includeVariants()
        .enabled()
        .productType(ProductType.MAIN)
        .descending(Product.UPDATE_AT_KEY)
        .limit(5);

      this._newProduct = query.rx().find().pipe(publishReplay(1)).pipe(refCount());
    }
    return this._newProduct;
  }

  public async findByMainBrand(brandId: string): Promise<ProductResult>  {
    return await Parse.Cloud.run('product-search', { brandId, type: ProductType.MAIN })
  }

  public firstMain() {
    if (!this._firstMain) {
        this._firstMain = this.brandService.firstMain().pipe(switchMap(async v => {
          const { rows } = await this.findByMainBrand(v.id)

          return rows
        }))
    }
    return this._firstMain;
  }

  public async firstProdMain() {
    const brand = await this.brandService.firstMain().toPromise()
    const result = await this.featureProductService.find(brand)
    return result.products;
  }

  public secondMain() {
    if (!this._secondMain) {
      this._secondMain = this.brandService.secondMain().pipe(switchMap(async v => {
        const { rows } = await this.findByMainBrand(v.id)

        return rows
      }))
    }
    return this._secondMain;
  }

  public async secondProdMain() {
    const brand = await this.brandService.secondMain().toPromise()
    const result = await this.featureProductService.find(brand)
    return result.products;
  }

  public thirdMain() {
    if (!this._thirdMain) {
      this._thirdMain = this.brandService.thirdMain().pipe(switchMap(async v => {
        const { rows } = await this.findByMainBrand(v.id)

        return rows
      }))
    }
    return this._thirdMain;
  }


  public async thirdProdMain() {
    const brand = await this.brandService.thirdMain().toPromise()
    const result = await this.featureProductService.find(brand)
    return result.products;
  }

  public view(productId: string) {
    return Parse.Cloud.run(PRODUCT_VIEW, { productId: productId });
  }

  // ===============================================================================================
  // Private Methods
  // ===============================================================================================

  private forCollection(collection: Observable<Collection>) {
    return collection.pipe(switchMap(o => {
      let query: ProductQuery = <ProductQuery>o.products.query()
        .include(Product.DEFAULT_VARIANT_KEY)
        .include([Product.DEFAULT_VARIANT_KEY, ProductVariant.IMAGES_KEY].join('.'))
        .include([Product.VARIANTS_KEY, [Product.VARIANTS_KEY, ProductVariant.IMAGES_KEY].join('.')])
        .equalTo(Product.ENABLED_KEY, true);

      return query.rx().find();
    })).pipe(publishReplay(1)).pipe(refCount());
  }

  private forCategory(category: Observable<Category>) {
    return category.pipe(switchMap(o => {
      let categories = _.compact(_.concat(o['children'], o));
      let query = new ProductQuery()
        .includeDefaultVariant()
        .includeVariants()
        .enabled()
        .notRemoved()
        .containedIn(Product.CATEGORIES_KEY,  categories)
        .descending(Product.UPDATE_AT_KEY);

      return query.rx().find();
    })).pipe(publishReplay(1)).pipe(refCount());
  }

  private forSubcategory(parent: Observable<Category>, index: number) {
    if (!parent['children']) {
      parent['children'] = [];
    }

    let products:Observable<Product[]> = parent['children'][index];

    if (products) {
      return products;
    }

    products = parent.pipe(switchMap(o => {
      let category = o['children'][index];
      let query = new ProductQuery()
        .includeDefaultVariant()
        .includeVariants()
        .enabled()
        .equalTo(Product.CATEGORIES_KEY, category)
        .descending(Product.UPDATE_AT_KEY);

      return query.rx().find();
    })).pipe(publishReplay(1)).pipe(refCount());

    parent['children'][index] = products;

    return products;
  }
}
