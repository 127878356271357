import {  } from './../queries/order-status-query';
import { Injectable } from '@angular/core';

import { Observable, Observer, Subscriber } from 'rxjs';

import { Order, OrderStatus } from './../data';
import { OrderStatusQuery } from './../queries';
import { map, publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class OrderStatusService {
  private _list: Observable<OrderStatus[]>

  constructor() {

  }

  public code(code: string) {
    return this.list().pipe(map(a => a.find(b => b.code == code)));
  }

  public list() {
    if (!this._list) {
      this._list = new OrderStatusQuery().rx().find().pipe(publishReplay(1)).pipe(refCount());
    }
    return this._list;
  }
}
