import { ParseQuery } from './parse-query';

import './../extensions/reg-exp+escape';

import { User, Role } from './../data';
import { RoleQuery } from './role-query';

export class UserQuery extends ParseQuery<User> {
  constructor(userId?: string) {
    super(User);

    if (userId) {
      this.equalTo('objectId', userId);
    }
  }

  public search(searchText: string) {
    let queries: Parse.Query[] = [];

    queries.push(new UserQuery().searchKey(User.FIRST_NAME_KEY, searchText));
    queries.push(new UserQuery().searchKey(User.LAST_NAME_KEY, searchText));
    queries.push(new UserQuery().searchKey(User.EMAIL_KEY, searchText));

    this.orQuery(queries);
    return this;
  }

  public includeAll() {
    this.include(User.PROFILE_PHOTO_KEY);
    this.include(User.DEFAULT_ADDRESS_KEY);
    return this;
  }

  public deleteUser(userId: string): Parse.Promise<any> {
    return Parse.Cloud.run('deleteUser', {objectId: userId});
  }
}