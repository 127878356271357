import { ParseQuery } from './parse-query';

import { AttachmentGroup } from './../data';

export class AttachmentGroupQuery extends ParseQuery<AttachmentGroup> {
  constructor() {
    super(AttachmentGroup);
  }

  public type(type: string) {
    this.equalTo(AttachmentGroup.TYPE_KEY, type);
    return this;
  }

  public search(searchText: string) {
    if (searchText) {
      this.matches(AttachmentGroup.NAME_KEY, RegExp.escape(searchText), 'i');
    }
    return this;
  }
}