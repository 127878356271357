import { Injectable } from '@angular/core';

import { Observable, Observer, Subscriber } from 'rxjs';

import { CityService } from './city-service';
import { ShippingZoneQuery } from './../queries/shipping-zone-query';
import { ShippingZone } from './../data/shipping-zone';
import { City } from './../data/city';

@Injectable()
export class ShippingZoneService {
  private _list: Observable<ShippingZone[]>

  constructor(private cityService: CityService) {

  }

  public list() {
    if (!this._list) {
      this._list = new ShippingZoneQuery()
        .equalTo(ShippingZone.ENABLED_KEY, true)
        .include(ShippingZone.CITY_KEY)
        .rx()
        .find()
        .publishReplay(1)
        .refCount();
    }
    return this._list;
  }

  public saveAll(objects: ShippingZone[]) {
    return new Observable<ShippingZone[]>((o: Subscriber<any>) => {
      let changes = objects.filter(o => o.dirty());

      Parse.Object.saveAll(changes).then(
        result => {
          o.next(result);
          o.complete();
        },
        error => {
          o.error(error);
        }
      )
    });
  }
}
