import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Event, EventGallery, EventGalleryItem } from '../data';
import { EventQuery } from '../queries';

@Injectable()
export class EventService {
  private _find: Observable<Event[]>;

  constructor() {

  }

  public permalink(permalink: string) {
    return new EventQuery()
      .include(Event.IMAGE_KEY)
      .include([Event.GALLERIES_KEY, EventGallery.ITEMS_KEY, EventGalleryItem.IMAGE_KEY].join('.'))
      .equalTo(Event.PERMALINK_KEY, permalink)
      .rx()
      .first();
  }
}

