import { Product } from '.';
import { ParseObject } from './parse-object';

export class Accessory extends ParseObject {
  public static readonly CLASS_NAME = 'Accessory';
  public static readonly MAIN_PRODUCT_KEY = 'mainProduct';
  public static readonly ACCESSORY_PRODUCT_KEY = 'accessoryProduct';

  constructor() {
    super(Accessory.CLASS_NAME);
  }

  static create() {
    const accessory = new Accessory()
    return accessory
  }

  public get mainProduct(): Product {
    return this.get(Accessory.MAIN_PRODUCT_KEY);
  }

  public set mainProduct(mainProduct: Product) {
    this.set(Accessory.MAIN_PRODUCT_KEY, mainProduct);
  }

  public get accessoryProduct(): Product {
    return this.get(Accessory.ACCESSORY_PRODUCT_KEY);
  }

  public set accessoryProduct(accessoryProduct: Product) {
    this.set(Accessory.ACCESSORY_PRODUCT_KEY, accessoryProduct);
  }
}

Parse.Object.registerSubclass(Accessory.CLASS_NAME, Accessory);