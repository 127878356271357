export class Pagination {
  public pageSize: number;
  public page: number;
  public collectionSize: number;

  constructor(pageSize: number = 21, page: number = 1, collectionSize: number = 0) {
    this.pageSize = pageSize;
    this.page = page;
    this.collectionSize = 0;
  }
}