import { ParseQuery } from './parse-query';

import './../extensions/reg-exp+escape';

import { Delivery } from './../data';

export class DeliveryQuery extends ParseQuery<Delivery> {
  constructor() {
    super(Delivery);
  }
}