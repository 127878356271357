import { Injectable } from '@angular/core';

import { Observable, Observer } from 'rxjs';

import { Attachment, AttachmentGroup } from './../data';
import { AttachmentGroupQuery } from './../queries';
import { ParseObservable } from './parse-observable';

@Injectable()
export class AttachmentGroupService {
  private _technicalBooks: Observable<AttachmentGroup[]>;
  private _videos: Observable<AttachmentGroup[]>;

  constructor() {

  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  public videos() {
    if (!this._videos) {
      this._videos = this.forType(AttachmentGroup.TYPE_VIDEO);
    }
    return this._videos;
  }

  public technicalBooks() {
    if (!this._technicalBooks) {
      this._technicalBooks = this.forType(AttachmentGroup.TYPE_TECHNICAL_BOOK);
    }
    return this._technicalBooks;
  }

  // ===============================================================================================
  // Private Methods
  // ===============================================================================================

  private forType(type: string) {
    let query = new AttachmentGroupQuery()
      .equalTo(AttachmentGroup.TYPE_KEY, type)
      .include(AttachmentGroup.ATTACHMENTS_KEY)
      .include([AttachmentGroup.ATTACHMENTS_KEY, Attachment.IMAGE_KEY].join('.'))
      .include([AttachmentGroup.ATTACHMENTS_KEY, Attachment.FILE_KEY].join('.'));

    return query.rx().find().publishReplay(1).refCount();
  }
}
