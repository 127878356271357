import { Injectable } from '@angular/core';

import { combineLatest, Observable, Observer, of } from 'rxjs';
import { flatMap, publishReplay, refCount } from 'rxjs/operators';

import { Filter, Collection } from './../data';
import { CollectionService } from './collection-service';

const POPULAR_LABEL = 'product.popular';
const ALL_LABEL = 'product.all';
const NEW_LABEL = 'product.new';

@Injectable()
export class FilterService {
  private _forCollections: Observable<Filter[]>;

  constructor(private collectionService: CollectionService) {

  }

  public forCollections() {
    if (this._forCollections) {
      return this._forCollections;
    }

    let mostPopular  = this.collectionService.mostPopular();
    let newArrivals  = this.collectionService.newArrivals();

    this._forCollections = combineLatest(mostPopular, newArrivals).pipe(flatMap(
      (value) => {
        let filters: Filter[] = [
          {
            type: Filter.COLLECTION_TYPE,
            label: ALL_LABEL,
          },
          {
            type: Filter.COLLECTION_TYPE,
            label: POPULAR_LABEL,
            value: value[0]
          },
          {
            type: Filter.COLLECTION_TYPE,
            label: NEW_LABEL,
            value: value[1]
          }
        ]
        return of(filters);
      }
    )).pipe(publishReplay(1)).pipe(refCount());

    return this._forCollections;
  }
}
